import React from "react"
import Img from "gatsby-image"

const imgGallery = (gallery, centre) => {
  if (gallery != null) {
    if (gallery.imageGallery !== null) {
      const rowClasses = centre === true ? "row center-bp1" : "row"
      const colClasses =
        centre === true ? "col-bp1-12 col-bp3-11" : "col-bp1-12"
      return (
        <div className={rowClasses}>
          <div className={colClasses}>
            <ul className="gallery">
              {gallery.imageGallery.map(item => {
                const name = item.localFile.name
                const url = item.localFile.childImageSharp.fluid
                return (
                  <li className="gallery__item" key={name}>
                    <Img fluid={url} alt={name} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )
    }
  }
}

export default imgGallery
