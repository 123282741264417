import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Header from "../components/header"
import Layout from "../layouts"
import Seo from "../components/seo"
import imgGallery from "../utils/imgGallery"

class PostTemplate extends Component {
  render() {
    const post = this.props.data.allWpPost.edges[0].node,
      seoTitle = post.title,
      seoDesc = ""
    const content = post.content
    const gallery =
      post.imageGalleryContent != null ? post.imageGalleryContent : null

    return (
      <Layout>
        <div className="page">
          <Seo seoTitle={seoTitle} seoDesc={seoDesc} />
          <Header />

          <section className="section sectionBlue">
            <div className="containerFluid">
              <div className="row center-bp1">
                <div className="col-bp1-12 col-bp2-9 col-bp6-7 col-bp7-6">
                  <div className="intro">
                    <h2
                      dangerouslySetInnerHTML={{ __html: post.title }}
                      className="intro__title"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section sectionWhite">
            <div className="containerFluid">
              <div className="row center-bp1">
                <div className="col-bp1-12 col-bp3-8 col-bp5-6">
                  <div
                    className="entry"
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div>
                </div>
              </div>
              {imgGallery(gallery, true)}
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    allWpPost(filter: { id: { eq: $id } }) {
      edges {
        node {
          databaseId
          title
          content
          date(formatString: "MMMM DD, YYYY")
          imageGalleryContent {
            imageGallery {
              localFile {
                name
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
